import { useUserStore } from './user.store'

import { userMeGet } from '../api/userMeGet.api'

export async function fetchMe(): Promise<boolean> {
  const store = useUserStore()
  if (store.loaded) return true
  const user = await userMeGet()
  if (user) {
    store.setMe(user)
    store.loaded = true
    return true
  }
  return false
}

export function refetchMe(): Promise<boolean> {
  const store = useUserStore()
  store.loaded = false
  return fetchMe()
}
