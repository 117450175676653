import type { User } from '../types'

// type ApiResponseUser = User & {
//   plan: User['plan'] & {
//     injuries: keyof User['plan']['injuries']
//   }
// }

type ApiResponse = {
  success: boolean
  user: User
}

const API_REQUEST_PATH = '/user/me'

export async function userMeGet() {
  const response = await useApi<ApiResponse>(API_REQUEST_PATH)
  return response.isOk && response.data?.user ? response.data?.user : undefined
}

// export async function userMeGet() {
//   const response = await useApi<ApiResponse>(API_REQUEST_PATH)
//   if (response.isOk && response.data?.user) {
//     return [response, mapper(response.data.user) as User]
//   }
//   return [response, undefined]
// }

// function mapper(dto: ApiResponseUser): User {
//   return {
//     ...dto,
//     plan: {
//       ...dto.plan,
//       injuries: {
//         none: !dto.plan.injuries.length,
//         back: dto.plan.injuries.includes('back'),
//         knee: dto.plan.injuries.includes('knee'),
//         shoulders: dto.plan.injuries.includes('shoulders'),
//       },
//     },
//   }
// }
